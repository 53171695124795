<template>
  <div id="app" class="h-100 w-100">
    <transition name="fade" mode="out-in">
      <div class="h-100 w-100 d-flex justify-content-center align-items-center" key="1" v-if="resultsMode === true">
        <div style="position: absolute; right:5%; top:5%; z-index: 1000">
          <button type="button" class="btn btn-dark" v-on:click="switchViewMode">
            Πίσω
          </button>
        </div>
        <div class="h-75 w-75 d-flex justify-content-center flex-column" v-if="loadMode">
          <div class="row justify-content-center">
            <label for="fromFile" class="form-label">
              Επέλεξε το αρχείο:
            </label>
          </div>
          <div class="row justify-content-center m-5">
            <input type="file" id="fromFile" ref="file">
          </div>
          <div class="row justify-content-center">
            <button id="saveBtn" type="button" class="btn btn-success p-3 m-5"
                    v-on:click="load">
              Διάβασε από το αρχείο
            </button>
          </div>
        </div>
        <div class="h-75 w-75 d-flex justify-content-center flex-column" v-else>
          <div class="row justify-content-center">
            <label for="evaluationTextArea"><i><b>Ονόμασε το αρχείο σου:</b></i></label>
          </div>
          <div class="row justify-content-center">
            <textarea id="evaluationTextArea" v-model="fileName"></textarea>
          </div>
          <div class="row justify-content-center">
            <button id="saveBtn" type="button" class="btn btn-success p-3 m-5"
                    v-on:click="save">
              Σώσε
            </button>
          </div>
        </div>
      </div>
      <div class="h-100 w-100" key="2" v-if="resultsMode === false">
        <div style="position: absolute; right:5%; top:5%; z-index: 1000">
          <!--          <button type="button" class="btn btn-danger" v-on:click="reset">
                      RESET
                    </button>-->
        </div>
        <div class="col-12 h-100 container">
          <div class="row col-12 d-flex justify-content-center p-3">
          </div>
          <div class="row col-12 d-flex justify-content-center p-2" v-if="this.element">
            <div class="border text-center align-self-center">{{ this.element.id + 1 }} / {{
                this.elements.length
              }}
            </div>
          </div>
          <div class="row col-12 d-flex justify-content-center p-2" v-if="this.element">
            <div class="text-center align-self-center"><b> {{ this.element.lemma }} </b></div>
          </div>
          <div class="row col-12 d-flex justify-content-center p-2" style="height: 40%" v-if="this.element">
            <div class="col-5 border d-flex align-items-center flex-column h-100">
              <div class="h4 p-4">A</div>
              <div style="overflow: auto">
                {{ this.summary_A }}
              </div>
            </div>
            <div class="col-5 border d-flex align-items-center flex-column h-100">
              <div class="h4 p-4">B</div>
              <div style="overflow: auto">
                {{ this.summary_B }}
              </div>
            </div>
          </div>
          <div class="row col-12 d-flex justify-content-between p-2" v-if="this.element">
            <div class="col-2 d-flex justify-content-start align-content-center flex-column">
              <div class="row justify-content-center">
                <button type="button" class="btn btn-primary" v-on:click="previousButton"
                        v-bind:disabled="preventNextBack">Προηγούμενο
                </button>
              </div>
              <div class="row mt-3 justify-content-center">
                <button type="button" class="btn btn-primary" v-on:click="previousButton10"
                        v-bind:disabled="preventNextBack">Προηγούμενο (X10)
                </button>
              </div>
            </div>
            <div class="col-8 container">
              <div class="row d-flex flex-row col-12 justify-content-center align-content-center">
                <div class="p-1 col-6"><b> Ποια από τα 2 κείμενα σου είναι πιο εύκολο να το καταλάβεις;</b></div>
                <div class="col-2 form-check p-1">
                  <input class="form-check-input" type="radio" id="selectA" value="A"
                         v-model="summary_picked">
                  <label class="form-check-label" for="selectA">
                    A
                  </label>
                </div>
                <div class="col-2 form-check p-1">
                  <input class="form-check-input" type="radio" id="selectB" value="B"
                         v-model="summary_picked">
                  <label class="form-check-label" for="selectB">
                    B
                  </label>
                </div>
              </div>
              <div class="row container col-12 pt-2 justify-content-center">
                <div class="row col-12 justify-content-center">
                  <div class="col-4"></div>
                  <div class="col-6">Διάλεξε <b>τουλάχιστον 1</b> λόγο:</div>
                  <div class="col-2"></div>
                </div>
                <div class="row col-12 justify-content-center">
                  <div class="col-4"></div>
                  <div class="col-6 container justify-content-center">
                    <div class="row form-check form-check">
                      <input class="form-check-input" type="checkbox" id="simple_words_checkbox"
                             value="simple_words" v-model="simple_words"
                             v-bind:disabled="summary_picked === null">
                      <label class="form-check-label" for="simple_words_checkbox">Έχει πιο απλές ΛΕΞΕΙΣ</label>
                    </div>
                    <div class="row form-check form-check">
                      <input class="form-check-input" type="checkbox" id="simple_sentences_checkbox"
                             value="simple_sentences" v-model="simple_sentences"
                             v-bind:disabled="summary_picked === null">
                      <label class="form-check-label" for="simple_sentences_checkbox">Έχει πιο απλές ΠΡΟΤΑΣΕΙΣ</label>
                    </div>
                    <div class="row form-check form-check">
                      <input class="form-check-input" type="checkbox" id="closer_to_what_I_know_checkbox"
                             value="closer_to_what_I_know" v-model="closer_to_what_I_know"
                             v-bind:disabled="summary_picked === null">
                      <label class="form-check-label" for="closer_to_what_I_know_checkbox">Είναι πιο κοντά σε αυτά που
                        ξέρω ήδη</label>
                    </div>
                    <div class="row form-check form-check">
                      <input class="form-check-input" type="checkbox" id="other_checkbox"
                             value="other"
                             v-model="otherReason" v-bind:disabled="summary_picked === null">
                      <label class="form-check-label" for="other_checkbox">Για κάποιο άλλο λόγο</label>
                    </div>
                  </div>
                  <div class="col-2"></div>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-start align-content-center flex-column">
              <div class="row justify-content-center">
                <button type="button" class="btn btn-primary" v-on:click="nextButton"
                        v-bind:disabled="preventNextBack">Επόμενο
                </button>
              </div>
              <div class="row mt-3 justify-content-center">
                <button type="button" class="btn btn-primary" v-on:click="nextButton10"
                        v-bind:disabled="preventNextBack">Επόμενο (X10)
                </button>
              </div>
            </div>
          </div>
          <div class="row col-12 d-flex justify-content-center" v-if="this.element">
            <div class="container col-4">
              <div class="row col-12 justify-content-center">
                <div>Επισημειώσεις που έχω κάνει:</div>
              </div>
              <div class="row col-12 justify-content-center">
                <div>{{ Object.keys(this.results).length }} / {{ this.elements.length }}</div>
              </div>
              <div class="row col-12 justify-content-center">
                <button id="nextToAnnotateBtn" type="button" class="btn btn-primary p-3 m-5"
                        v-bind:disabled="preventNextAnnotation"
                        v-on:click="nextToAnnotate">

                  Επόμενο μη-επισημειωμένο
                </button>
              </div>
              <div class="row col-12 justify-content-center">
                <button id="saveBtn" type="button" class="btn btn-success p-3 m-5"
                        v-on:click="switchViewMode(false)">

                  Σώσε σε αρχείο
                </button>
                <button id="loadBtn" type="button" class="btn btn-warning p-3 m-5"
                        v-on:click="switchViewMode(true)">
                  Διάβασε από αρχείο
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import Papa from 'papaparse';

export default {
  name: 'app',
  components: {},
  data() {
    return {
      resultsMessage: "",
      resultsMode: false,
      evaluationId: null,
      results: {},
      elements: [],
      element: null,
      summary_picked: null,
      summary_A: null,
      summary_B: null,
      swapOfSummaries: [],
      simple_words: false,
      simple_sentences: false,
      closer_to_what_I_know: false,
      otherReason: false,
      fileName: "annotation.csv",
      loadMode: false
    }
  },
  computed: {
    preventNextBack: function () {
      if (this.summary_picked === null || this.isValidEvaluation())
        return false;
      else
        return true;
    },
    preventNextAnnotation: function () {
      if (this.elements.length - Object.keys(this.results).length < 2)
        return  true;
      else if (this.summary_picked === null)
        return  true;
      else if (!this.isValidEvaluation())
        return  true;
      else
        return false;
    }
  },
  methods: {
    load() {
      let instance = this;
      try {
        let inputFile = this.$refs.file.files[0];
        Papa.parse(inputFile, {
          complete: function (results) {
            let entry = results.data[0];
            if (entry[0] === "id") {
              for (var line in results.data) {
                entry = results.data[line];
                if (entry[0] !== "id") {
                  //console.log("entry:\t", entry);
                  let id = Number(entry[0]);
                  let A = (entry[1] === "true");
                  let B = (entry[2] === "true")
                  //console.log("id:\t", id, " A:\t", A, " B:\t", B);
                  let selected = "A";
                  if (B)
                    selected = "B";
                  let simpleWords = (entry[4] === "true");
                  let simpleSents = (entry[5] === "true");
                  let closerToWhatIKnow = (entry[6] === "true");
                  let otherReason = (entry[7] === "true");

                  if (instance.swapOfSummaries[id]) {
                    A = !A;
                    B = !B;
                    if (selected === "A")
                      selected = "B";
                    else
                      selected = "A";
                  }
                  //console.log("id:\t", id, " A:\t", A, " B:\t", B);
                  instance.results[id] = {
                    "id": id,
                    "A": A,
                    "B": B,
                    "selected": selected,
                    "simple_words": simpleWords,
                    "simple_sentences": simpleSents,
                    "closer_to_what_I_know": closerToWhatIKnow,
                    "otherReason": otherReason
                  };
                }
              }
              instance.continueLoad();
            } else {
              alert("To αρχείο που επιλέξατε είναι λάθος!");
            }
          }
        });
      } catch (e) {
        alert("To αρχείο που επιλέξατε είναι λάθος!");
      }
    },
    continueLoad() {
      console.log("instance.results:\t", this.results);
      this.updateEvaluationGUIGivenId(this.element.id);
      this.switchViewMode(true);
    },
    save() {
      const blob = new Blob([this.resultsMessage], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
          a = document.createElement('a');
      a.download = this.fileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    randomize(start, max) { //start 1 max id - 1
      let current = start;
      console.log("Randomizing from ", start, " to ", max);
      let step = start - 1 + 5;
      while (current < max - 5) {
        let aIndex = current;
        let bIndex = step;
        let a = this.elements[aIndex];
        let b = this.elements[bIndex];
        let temp = b.id;
        b.id = a.id;
        a.id = temp;
        this.elements[a.id] = a;
        this.elements[b.id] = b;

        current ++;
        step = step + 5;
        if (step > max)
          step = start - 1 + 3;
      }
    },loadCSV(instance) {
      instance.elements = [];
      fetch("input.csv")
          .then((res) => res.text())
          .then((text) => {
            let csv = Papa.parse(text);
            let id = 0;
            let start1 = 10000;
            let max1 = 0;
            let start2 = 10000;
            let max2 = 0;
            let start3 = 10000;
            let max3 = 0;

            for (var line in csv.data) {
              let entry = csv.data[line];
              let lemma = entry[1];
              if (typeof lemma !== 'undefined' &&  lemma !== "Λήμμα" && lemma !== "") {
                let initial_input = entry[2];
                let llm1_output = entry[4];
                let llm2_output = entry[5];
                let e1 = {id: id, lemma: lemma, A: initial_input, B: llm1_output};
                this.elements.push(e1);
                id++;
                let possible_start = id;
                let e2 = {id: id, lemma: lemma, A: initial_input, B: llm2_output};
                this.elements.push(e2);
                id++;
                let e3 = {id: id, lemma: lemma, A: llm1_output, B: llm2_output};
                this.elements.push(e3);
                let possible_max = id;
                id++;


                switch(entry[0]) {
                  case "Επαγγέλματα":
                    //console.log("Επαγγέλματα", lemma);
                    if (possible_start < start1)
                      start1 = possible_start;
                    if (possible_max > max1)
                      max1 = possible_max;
                    break;
                  case "Ζώα":
                    //console.log("Ζώα", lemma);
                    if (possible_start < start2)
                      start2 = possible_start;
                    if (possible_max > max2)
                      max2 = possible_max;
                    break;
                  case "Υλικά":
                    //console.log("Υλικά", lemma);
                    if (possible_start < start3)
                      start3 = possible_start;
                    if (possible_max > max3)
                      max3 = possible_max;
                    break;
                  default:
                    console.log("Default", lemma);
                    break;
                }
              }
            }
            //randomize
            instance.randomize(start1, max1);
            instance.randomize(start2, max2);
            instance.randomize(start3, max3);
            instance.reloadContinue();
          }).catch((e) => console.error(e));
    },
    switchViewMode(mode) {
      if (this.resultsMode)
        this.resultsMode = false;
      else {
        this.loadMode = mode;
        if (!mode) {
          this.updateResultsGivenId(this.element.id);
          let text = "id, A, B, selected, simple_words, simple_sentences,  closer_to_what_I_know, otherReason";
          for (let i = 0; i < this.elements.length; i++) {
            let result = this.results[i];
            if (result != null) {
              text = text + "\n" + result.id + "," + result.A + "," + result.B
                  + "," + result.selected + "," + result.simple_words + ","
                  + result.simple_sentences + "," + result.closer_to_what_I_know + "," + result.otherReason;
            }
          }
          this.resultsMessage = text;
        }
        this.resultsMode = true;
      }
    },
    nextToAnnotate() {
      let currentId = this.element.id;
      this.updateResultsGivenId(currentId);
      let isAnnotated = true;
      while (isAnnotated) {
        this.fetchNextInstance();
        currentId = this.element.id;
        if (! (currentId in this.results))
          isAnnotated = false;
      }
      this.updateEvaluationGUIGivenId(currentId);
    },
    nextButton() {
      this.updateResultsGivenId(this.element.id);
      this.fetchNextInstance();
      this.updateEvaluationGUIGivenId(this.element.id);
    },
    nextButton10() {
      this.updateResultsGivenId(this.element.id);
      for (let i = 0; i < 10; i++)
        this.fetchNextInstance();
      this.updateEvaluationGUIGivenId(this.element.id);
    },
    previousButton() {
      this.updateResultsGivenId(this.element.id);
      this.fetchPreviousInstance();
      this.updateEvaluationGUIGivenId(this.element.id);
    },
    previousButton10() {
      this.updateResultsGivenId(this.element.id);
      for (let i = 0; i < 10; i++)
        this.fetchPreviousInstance();
      this.updateEvaluationGUIGivenId(this.element.id);
    },
    updateResultsGivenId(id) {
      if (this.isValidEvaluation()) {
        if (this.swapOfSummaries[id] == false) {
          this.results[id] = {
            "id": this.element.id,
            "A": this.summary_picked === "A",
            "B": this.summary_picked === "B",
            "selected": this.summary_picked,
            "simple_words": this.simple_words,
            "simple_sentences": this.simple_sentences,
            "closer_to_what_I_know": this.closer_to_what_I_know,
            "otherReason": this.otherReason
          };
        } else {
          let summary_picked_tmp = "A";
          if (this.summary_picked === "A")
            summary_picked_tmp = "B";
          this.results[id] = {
            "id": this.element.id,
            "A": this.summary_picked === "B",
            "B": this.summary_picked === "A",
            "selected": summary_picked_tmp,
            "simple_words": this.simple_words,
            "simple_sentences": this.simple_sentences,
            "closer_to_what_I_know": this.closer_to_what_I_know,
            "otherReason": this.otherReason
          };
        }
      }
    },
    updateEvaluationGUIGivenId(id) {
      //console.log("id:\t", id, "results:\t", this.results);
      if (id in this.results) {
        let data = this.results[id];
        //console.log("data:\t", data);
        let summary_picked_tmp = data["selected"];
        if (this.swapOfSummaries[id]) {
          if (summary_picked_tmp === "A")
            this.summary_picked = "B";
          else
            this.summary_picked = "A";
        } else {
          this.summary_picked = summary_picked_tmp;
        }
        this.simple_words = data["simple_words"];
        this.simple_sentences = data["simple_sentences"];
        this.closer_to_what_I_know = data["closer_to_what_I_know"];
        this.otherReason = data["otherReason"];
      } else {
        this.summary_picked = null;
        this.simple_words = false;
        this.simple_sentences = false;
        this.closer_to_what_I_know = false;
        this.otherReason = false;
      }
    },
    isValidEvaluation() {
      if (this.summary_picked === null)
        return false;
      else if (this.simple_words === false && this.simple_sentences === false && this.closer_to_what_I_know === false && this.otherReason === false)
        return false;
      else
        return true;
    },
    performSwapOfSummaries(swap_needed) {
      if (swap_needed) {
        this.summary_A = this.element.B;
        this.summary_B = this.element.A;
      } else {
        this.summary_A = this.element.A;
        this.summary_B = this.element.B;
      }
    },
    fetchPreviousInstance() {
      if (this.elements.length > 0) {
        let current = this.element;
        let index = 0;
        if (current !== null) {
          let current_index = _.findIndex(this.elements, function (o) {
            return o.id === current.id
          });
          if (current_index > 0)
            index = current_index - 1;
          else
            index = this.elements.length - 1;
        }
        this.element = this.elements[index];
        this.performSwapOfSummaries(this.swapOfSummaries[index]);
      }
    },
    fetchNextInstance() {
      let current = this.element;
      let index = 0;
      if (current !== null) {
        let current_index = _.findIndex(this.elements, function (o) {
          return o.id === current.id
        });
        if (current_index < this.elements.length - 1)
          index = current_index + 1;
        else
          index = 0;
      }
      this.element = this.elements.find(e => e.id === index);
      this.performSwapOfSummaries(this.swapOfSummaries[index]);
    },
    reload() {
      console.log("reload");
      let instance = this;
      this.loadCSV(instance);
    },
    reloadContinue() {
      let instance = this;
      console.log("Called", instance.elements);
      for (let i = 0; i < instance.elements.length; i++) {
        let r = Math.floor(Math.random() * 2)//0 or 1
        if (r === 0)
          instance.swapOfSummaries.push(false);
        else
          instance.swapOfSummaries.push(true);
      }
      instance.fetchNextInstance();
      instance.evaluationId = '_' + Math.random().toString(36).substr(2, 9);

    },
    reset() {
      let instance = this;
      this.$dialog.confirm('Are you sure?').then(function () {
        instance.results = {};
        instance.elements = [];
        instance.element = null;
        instance.summary_picked = null;
        instance.summary_A = null;
        instance.summary_B = null;
        instance.swapOfSummaries = [];
        instance.simple_words = false;
        instance.simple_sentences = false;
        instance.closer_to_what_I_know = false;
        instance.otherReason = false;
        instance.reload();
      }).catch(function () {
        console.log("Clicked on cancel");
      });
    }
  },
  mounted() {
    this.reload();
  }
}
</script>
<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vuejs-dialog/dist/vuejs-dialog.min.css';

html,
body {
  height: 100%;
  margin: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>