import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VuejsDialog from 'vuejs-dialog';


Vue.use(BootstrapVue);
Vue.use(require('vue-cookies'));
Vue.use(VuejsDialog,{
  html: true,
  loader: false,
  okText: 'Yes',
  cancelText: 'Cancel',
  animation: 'fade'
});

new Vue({
  render: h => h(App),
}).$mount('#app')
